import React from 'react'
import { useSelector } from 'react-redux'
import NewDetails from './NewDetails'
import ConnectWallet from './ConnectWallet'
const Balances = () => {
    const { account } = useSelector(state => state.contracts)
    return (
        <>
                <section className="">
                <div className="container my-3 my-lg-5">
                    <div className="heading d-flex flex-column align-items-center my-3 my-lg-5">
                        <div>
                            <h3 className="display-5 fw-bold">My Balances</h3>
                            <div>
                                <img src="./images/heading.png" alt="nice" />
                            </div>
                        </div>
                    </div>
                    {
                        account ?
                            <>
                                <NewDetails />
                                <div className="row mt-3 mt-lg-5">
                                    <div className="col-lg-12">
                                        <div className="glass p-3 p-lg-4">
                                            <h5 className="mb-0 fw-bold">Account Address : {account}</h5>
                                        </div>
                                    </div>
                                </div>
                            </>
                            :
                            <>
                                <div className="d-flex flex-column justify-content-center align-items-center">
                                    <h2 className='text-center'>Please Connect your wallet to see your details</h2>
                                    <div className="my-3">
                                        <ConnectWallet />
                                    </div>
                                </div>
                            </>
                    }
                </div>
            </section>
        </>
    )
}

export default Balances