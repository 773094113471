import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'

const Details = () => {
    const { account, daiToken, MSCoin, TokenFarm, MaticStake  } = useSelector(state => state.contracts)
    const [daiTokenBalance, setDaiTokenBalance] = useState("")
    const [MSCoinBalance, setMSCoinBalance] = useState("")
    const [stackingBalance, setStackingBalance] = useState("")
    const [yieldTotal, setYieldTotal] = useState("")
    const [yieldTime, setYieldTime] = useState("")
    const [loading, setLoading] = useState(true)
    const [time, setTime] = useState({})


    const [newStackingBalance, setNewStackingBalance] = useState("")
    const [newreward, setNewReward] = useState("")
    const [newTime, setNewTime] = useState("")
    const [newTotal, setNewTotal] = useState("")
    const [newBalance, setNewBalance] = useState("")

    const getTime = (duration) => {
        var milliseconds = parseInt((duration % 1000) / 100),
            seconds = Math.floor((duration ) % 60),
            minutes = Math.floor((duration / (60)) % 60),
            hours = Math.floor((duration / (60 * 60)) % 24),
            days = Math.floor((duration / ( 60 * 60 * 24)) % 365);

        hours = (hours < 10) ? "0" + hours : hours;
        minutes = (minutes < 10) ? "0" + minutes : minutes;
        seconds = (seconds < 10) ? "0" + seconds : seconds;

        return {days,hours, minutes, seconds};
    }
    useEffect(async () => {
        if (account && daiToken && MSCoin && TokenFarm && MaticStake) {
            // DaiToken Balance
            let daiTokenBalance1 = await daiToken.methods.balanceOf(account).call()
            setDaiTokenBalance(window.web3.utils.fromWei(daiTokenBalance1))

            // MSCoin balance
            let mscoinTokenBalance = await MSCoin.methods.balanceOf(account).call()
            setMSCoinBalance(window.web3.utils.fromWei(mscoinTokenBalance))

            // Stacking Balance
            let stakingBalance = await TokenFarm.methods.stakingBalance(account).call()
            setStackingBalance(window.web3.utils.fromWei(stakingBalance))

            //Yield Total Amount
            let calculateYieldTotal = await TokenFarm.methods.calculateYieldTotal(account).call()
            setYieldTotal(window.web3.utils.fromWei(calculateYieldTotal))

            // Yield Time 
            let calculateYieldTime = await TokenFarm.methods.calculateYieldTime(account).call()
            setYieldTime(calculateYieldTime)

            
            let newStakingBal = await MaticStake.methods.calculateYieldTime(account).call()
            setNewStackingBalance(newStakingBal)

            // let newReward = await MaticStake.methods.calculateYieldTime(account).call()
            // setNewStackingBalance(newStakingBal)
            
            // let newTotal = await MaticStake.methods.calculateYieldTime(account).call()
            // setNewStackingBalance(newStakingBal)
            
            let newTime = await MaticStake.methods.calculateYieldTime(account).call()
            setNewTime(newTime)

            let newBalance = await MaticStake.methods.getunstakeTokens(account).call()
            setNewStackingBalance(newBalance)
            setLoading(false)
        }
    }, [account, TokenFarm, MSCoin, daiToken, MaticStake])
    
    useEffect(() => {
        if (yieldTime) {
            const time1 = getTime(yieldTime)
            setTime(time1)
        }
    }, [yieldTime])


    return (
        <>
            {
                loading ?
                    <div className="d-flex justify-content-center">
                        <img src="./images/loading.gif" alt="loading" className='loading' />
                    </div>
                    :
                    <div className="row align-items-center">
                        <div className="col-lg-3 my-3">
                            <div className="glass p-3">
                                <div className="d-flex flex-column align-items-center">
                                    <h3 className="theme fw-bold">Stacking Balance</h3>
                                    <h6 className="h6 fw-bold">{stackingBalance}</h6>
                                    <h4 className="mb-0 fw-bolder">mDai</h4>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 my-3">
                            <div className="glass p-3">
                                <div className="d-flex flex-column align-items-center">
                                    <h3 className="theme fw-bold">Reward Balance</h3>
                                    <h6 className="h6 fw-bold">{MSCoinBalance}</h6>
                                    <h4 className="mb-0 fw-bolder">MS Coin</h4>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 my-3">
                            <div className="glass p-3">
                                <div className="d-flex flex-column align-items-center">
                                    <h3 className="theme fw-bold">Yield Total</h3>
                                    <h6 className="h6 fw-bold">{yieldTotal}</h6>
                                    <h4 className="mb-0 fw-bolder">MS Coin</h4>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 my-3">
                            <div className="glass p-3">
                                <div className="d-flex flex-column align-items-center">
                                    <h3 className="theme fw-bold">Yield Time</h3>
                                    <h6 className=" h6 fw-bold">
                                        {`${time.days} : ${time.hours} : ${time.minutes}`}
                                    </h6>
                                    <h4 className="mb-0 fw-bolder">Days</h4>
                                </div>
                            </div>
                        </div>
                    </div>
            }
        </>
    )
}

export default Details