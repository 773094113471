import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'

const NewDetails = ({setchangeValue,changeValue}) => {
    const { account, daiToken, MSCoin, TokenFarm, MaticStake } = useSelector(state => state.contracts)
    const [daiTokenBalance, setDaiTokenBalance] = useState("")
    const [MSCoinBalance, setMSCoinBalance] = useState("")
    const [stackingBalance, setStackingBalance] = useState("")
    const [yieldTotal, setYieldTotal] = useState("")
    const [yieldTime, setYieldTime] = useState("")
    const [loading, setLoading] = useState(true)
    const [time, setTime] = useState({})


    const [newStackingBalance, setNewStackingBalance] = useState("")
    const [newreward, setNewReward] = useState("")
    const [newTime, setNewTime] = useState("")
    const [newTotal, setNewTotal] = useState("")
    const [newBalance, setNewBalance] = useState("")

    const getTime = (duration) => {
        var milliseconds = parseInt((duration % 1000) / 100),
            seconds = Math.floor((duration) % 60),
            minutes = Math.floor((duration / (60)) % 60),
            hours = Math.floor((duration / (60 * 60)) % 24),
            days = Math.floor((duration / (60 * 60 * 24)) % 365);

        hours = (hours < 10) ? "0" + hours : hours;
        minutes = (minutes < 10) ? "0" + minutes : minutes;
        seconds = (seconds < 10) ? "0" + seconds : seconds;

        return { days, hours, minutes, seconds };
    }
    useEffect(async () => {
        setLoading(true)
        if (account && daiToken && MSCoin && TokenFarm && MaticStake) {
            // DaiToken Balance
            let daiTokenBalance1 = await daiToken.methods.balanceOf(account).call()
            setDaiTokenBalance(window.web3.utils.fromWei(daiTokenBalance1))

            // MSCoin balance
            let mscoinTokenBalance = await MSCoin.methods.balanceOf(account).call()
            setMSCoinBalance(window.web3.utils.fromWei(mscoinTokenBalance))

            // Stacking Balance
            let stakingBalance = await TokenFarm.methods.stakingBalance(account).call()
            setStackingBalance(window.web3.utils.fromWei(stakingBalance))

            //Yield Total Amount
            let calculateYieldTotal = await TokenFarm.methods.calculateYieldTotal(account).call()
            setYieldTotal(window.web3.utils.fromWei(calculateYieldTotal))

            // Yield Time 
            let calculateYieldTime = await TokenFarm.methods.calculateYieldTime(account).call()
            setYieldTime(calculateYieldTime)


            try {
                let newStakingBal = await MaticStake.methods.getStakeTokens().call({ from: account, to: '0xdD08AEB770Db930C4E3D6C229bFa2A477c8e7C6E' })
                setNewStackingBalance(newStakingBal/1000000000000000000)
            } catch (err) {
                window.alert(err.message)
            }

            let newReward = await MaticStake.methods.calculateYieldTotal(account).call({ to: '0xdD08AEB770Db930C4E3D6C229bFa2A477c8e7C6E' })
            setNewReward(newReward/1000000000000000000)

            let newTime = await MaticStake.methods.calculateYieldTime(account).call({ to: '0xdD08AEB770Db930C4E3D6C229bFa2A477c8e7C6E' })
            setNewTime(newTime)

        
            let newBalance = await MaticStake.methods.getunstakeTokens()
                .call({ from: account, to: '0xdD08AEB770Db930C4E3D6C229bFa2A477c8e7C6E' })
            // .once("error", (err) => {
            //     toast.error(err.message,{theme:'dark'})
            // }).then(() => {
            //     toast.success("Stack Successfully",{theme:'dark'})
            // })

            setNewBalance(newBalance/1000000000000000000)
            // let newBalance = await MaticStake.methods.getunstakeTokens().call()
            // setNewStackingBalance(newBalance)

            setLoading(false)

            //  New contract parameters

            //Calculate Time
            // const day = (yieldTime / (86400 * 1000)).toString().split('.')[0]
            // setDays(day)
            // const hour = (yieldTime / ((3600 * 1000) - (day * 24))).toString().split('.')[0]
            // setHours(hou r)
            // const minute = (yieldTime / ((60 * 1000)- (day * 24) - (hour * 60))).toString().split('.')[0]
            // setMinutes(minute)
        }
        setLoading(false)
    }, [account, TokenFarm, MSCoin, daiToken, MaticStake,changeValue])
    useEffect(() => {
        if (yieldTime) {
            const time1 = getTime(newTime)
            setTime(time1)
        }
    }, [yieldTime])


    return (
        <>
            {
                loading ?
                    <div className="d-flex justify-content-center">
                        <img src="./images/loading.gif" alt="loading" className='loading' />
                    </div>
                    :
                    <div className="row align-items-center">
                        <div className="col-lg-3 my-3">
                            <div className="glass p-3">
                                <div className="d-flex flex-column align-items-center">
                                    <h3 className="theme fw-bold">Stacking Balance</h3>
                                    <h6 className="h6 fw-bold">{newStackingBalance}</h6>
                                    <img src="/images/mdai.png" alt="img" className='img-fluid' style={{height:30}} />
                                    {/* <h4 className="mb-0 fw-bolder">Eth</h4> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 my-3">
                            <div className="glass p-3">
                                <div className="d-flex flex-column align-items-center">
                                    <h3 className="theme fw-bold">Balance</h3>
                                    <h6 className="h6 fw-bold">{newBalance}</h6>
                                    <img src="/images/mdai.png" alt="img" className='img-fluid' style={{height:30}} />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 my-3">
                            <div className="glass p-3">
                                <div className="d-flex flex-column align-items-center">
                                    <h3 className="theme fw-bold">Reward Generated</h3>
                                    <h6 className="h6 fw-bold">{newreward}</h6>
                                    <img src="/images/mdai.png  " alt="img" className='img-fluid' style={{height:30}} />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 my-3">
                            <div className="glass p-3">
                                <div className="d-flex flex-column align-items-center">
                                    <h3 className="theme fw-bold">Yield Time</h3>
                                    <h6 className=" h6 fw-bold">
                                        {`${time.days} : ${time.hours} : ${time.minutes}`}
                                    </h6>
                                    <h4 className="mb-0 fw-bolder">Days</h4>
                                </div>
                            </div>
                        </div>
                    </div>
            }
        </>
    )
}

export default NewDetails