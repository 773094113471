import { configureStore } from "@reduxjs/toolkit";
import contractSlice from "./contractSlice";
export default configureStore({
    reducer:{
        contracts : contractSlice
    },
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
})