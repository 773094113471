import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Web3 from 'web3';
import { useSelector, useDispatch } from 'react-redux';
import { setAccount, setDaiToken, setMaticStake, setMSCoin, setTokenFarm } from '../store/contractSlice';
import DaiToken from '../abis/DaiToken.json';
import MSCoin from '../abis/MSCoin.json';
import MaticSatke from '../abis/stacknew.json';
import TokenFarm from '../abis/TokenFarm.json'
const ConnectWallet = () => {
    const [render, setRender] = useState(1)
    const dispatch = useDispatch();
    const web3 = window.web3
    const { account } = useSelector(state => state.contracts)

    const loadWeb3 = async () => {
        if (window.ethereum) {
            window.web3 = new Web3(window.ethereum)
            await window.ethereum.enable()
            await loadBlockChainData()
        } else {
            toast("Non-ethereum browser detected ! Try using Metamask Chrome Extention", { theme: "dark", type: "error" })
            return false
        }
    }
    const loadBlockChainData = async () => {
        const accounts = await window.web3.eth.getAccounts()
        dispatch(setAccount(accounts[0]))
        setRender(2)
        const networkId = await window.web3.eth.net.getId()
        if (networkId == 4) {
            // DaiToken
            const daiToken = new window.web3.eth.Contract(DaiToken, "0x948A177d4fc163A806831AC8E9A510B531c1CF00")
            if (daiToken) {
                dispatch(setDaiToken(daiToken))
                // get daiToken Balance
                // let daiTokenBalance = await daiToken.methods.balanceOf(account).call()
            }

            // MSCoin
            const mscoin = new window.web3.eth.Contract(MSCoin, "0x5B79DbD9c760935683ea0f882378e85eF5aF922c")
            if (mscoin) {
                dispatch(setMSCoin(mscoin))
                // mscoin balance
                // let mscoinTokenBalance = await mscoin.methods.balanceOf(account).call()
            }

            // Token Farm
            const tokenFarm = new window.web3.eth.Contract(TokenFarm, "0x109Cce08a19D2df7a9536d48DE9e5118C3b700D7")
            if (tokenFarm) {
                dispatch(setTokenFarm(tokenFarm))
                // Stacking Balance
                // let stakingBalance = await tokenFarm.methods.stakingBalance(account?account:accounts[0]).call();
            }
            const maticSatke = new window.web3.eth.Contract(MaticSatke, "0xdD08AEB770Db930C4E3D6C229bFa2A477c8e7C6E")
            if (maticSatke) {
                dispatch(setMaticStake(maticSatke))
                // Stacking Balance
                // let stakingBalance = await tokenFarm.methods.stakingBalance(account?account:accounts[0]).call();
            }

        } else {
            toast("Please,Connect to Rinkby test net", { theme: "dark", type: "error" })
        }
    }
    useEffect(async () => {
        if (window.ethereum) {
            await loadWeb3()
        }
    }, []);
    return (
        <>
            {
                account ?
                    <button className="btn button py-2 px-4 fw-bold" style={{ fontSize: "18px" }}>{`${account.substring(0, 8)}...`}</button>
                    :
                    <button className="btn button py-2 px-4 fw-bold" style={{ fontSize: "18px" }} onClick={loadWeb3}>Connect Wallet</button>
            }

        </>
    )
}

export default ConnectWallet