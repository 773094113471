import React from 'react'
import { Link } from 'react-router-dom'
import ConnectWallet from './ConnectWallet'

const Navbar = () => {
    return (
        <header>
            <div className="container my-3 my-lg-5">
                <div className="glass p-4">
                    <div className="d-flex align-items-center justify-content-between">
                        {/* <h2 className="mb-0 fw-bold">👲 Crebaco</h2> */}
                        <img src="/images/logo.png" alt="logo" height={80}/>
                        <ul className="headerList">
                            <li><Link to="/">Home</Link></li>
                            <li><Link to="/about">About Us</Link></li>
                            <li><Link to="/balance">My Balances</Link></li>
                            <li><Link to="/stackingnew">Stacking</Link></li>
                            <li><Link to="/withdrawal">withdrawal</Link></li>
                        </ul>
                        
                        <ConnectWallet />
                    </div>
                </div>
            </div>
        </header>
    )
}

export default Navbar