import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import About from './components/About';
import Balances from './components/Balances';
import Stacking from './components/Stacking';
import StackingNew from './components/StackingNew';
import Withdrawal from './components/Withdrawal';
import Navbar from './components/Navbar';
import { useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DaiToken from './abis/DaiToken.json'
import Web3 from 'web3'
const App = () => {
    const [changeValue, setchangeValue] = useState(1)
    return (
        <>
            <Router>
                <Navbar></Navbar>
                <ToastContainer/>
                <Routes>
                    <Route path='/' element={<Home changeValue={changeValue} setchangeValue={setchangeValue}/>}></Route>
                    <Route path='/about' element={<About changeValue={changeValue} setchangeValue={setchangeValue}/>}></Route>
                    <Route path='/balance' element={<Balances changeValue={changeValue} setchangeValue={setchangeValue}/>}></Route>
                    <Route path='/stacking' element={<Stacking changeValue={changeValue} setchangeValue={setchangeValue}/>}></Route>
                    <Route path='/stackingnew' element={<StackingNew changeValue={changeValue} setchangeValue={setchangeValue}/>}></Route>
                    <Route path='/withdrawal' element={<Withdrawal changeValue={changeValue} setchangeValue={setchangeValue}/>}></Route>
                </Routes>
            </Router>
        </>
    );
}

export default App;
