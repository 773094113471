import React, { useEffect, useState  } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import ConnectWallet from './ConnectWallet'
import Details from './Details'
import NewDetails from './NewDetails'

const Withdrawal = ({setchangeValue,changeValue}) => {
    // const { account, daiToken, TokenFarm } = useSelector(state => state.contracts)
    const { account, daiToken, TokenFarm ,MaticStake} = useSelector(state => state.contracts)
    const [amount, setAmount] = useState(0)
    const [loading, setLoading] = useState(true)

    const [newBalance, setNewBalance] = useState("")

    const Withdraw = () => {
        // setLoading(true)
        if (newBalance) {
            MaticStake.methods.withdrawUnstakeTokens()
            .send({from:account,to:'0xdD08AEB770Db930C4E3D6C229bFa2A477c8e7C6E'})
            .once("error", (err) => {
                toast.error(err.message,{theme:'dark'})
            }).then(() => {
                toast.success("Withdraw Successfully",{theme:'dark'})
                setchangeValue(8)
            })
        } else {
            toast.error("You don't have enough coin for withdraw",{theme:'dark'})
        }
    }

    useEffect(async () => {
        if (account && daiToken && TokenFarm && MaticStake) {
            let newBalance = await MaticStake.methods.getunstakeTokens()
                .call({ from: account, to: '0xdD08AEB770Db930C4E3D6C229bFa2A477c8e7C6E' })
            setNewBalance(newBalance)
            setLoading(false)
        }
    }, [account, TokenFarm, daiToken, MaticStake])

    return (
        <section className="">
            <div className="container my-3 my-lg-5">
                {
                    !account ?
                        <>
                            <div className="d-flex flex-column justify-content-center align-items-center">
                                <h2 className='text-center'>Please Connect your wallet to see your details</h2>
                                <div className="my-3">
                                    <ConnectWallet />
                                </div>
                            </div>
                        </>
                        :
                        <>
                            <div className="row align-items-center">
                                <div className="col-lg-12 my-3 my-lg-5">
                                    <div className="glass p-3 p-lg-4">
                                        <h3 className="mb-0 fw-bold">Stack Token</h3>
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="glass p-4 p-lg-5">
                                        <div className="input-mdai">
                                            <div type="text" className="form-control glass p-3" placeholder="Enter Value here" ><h4 className="mb-0">
                                            {newBalance/1000000000000000000}</h4></div>
                                            <img src="./images/mdai.png" alt="ETH" className="img-fluid mdai" />
                                        </div>
                                        
                                        <div className="row">
                                            <div className="col-md-12">
                                                <button className="button btn w-100 mt-3 mt-lg-4 fw-bold" onClick={Withdraw}>Withdraw !</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <NewDetails changeValue={changeValue} setchangeValue={setchangeValue}/>
                        </>
                }
            </div>
        </section>
    )
}

export default Withdrawal