import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import ConnectWallet from './ConnectWallet'
import Details from './Details'

const Stacking = () => {
    // const { account, daiToken, TokenFarm } = useSelector(state => state.contracts)
    const { account, daiToken, TokenFarm } = useSelector(state => state.contracts)
    const [amount, setAmount] = useState(0)
    const [loading, setLoading] = useState(true)
    const StackToken = () => {
       // setLoading(true)
        daiToken.methods.approve(TokenFarm._address,amount).send({from:account})
        .on('transactionHash',async (hash) => {
            TokenFarm.methods.stackTokens(amount).send({from:account})
        })
        .on('transactionHash',async (hash) => {
            setLoading(false)
        })
    }
    return (
        <section className="">
            <div className="container my-3 my-lg-5">
                {
                    !account ?
                        <>
                            <div className="d-flex flex-column justify-content-center align-items-center">
                                <h2 className='text-center'>Please Connect your wallet to see your details</h2>
                                <div className="my-3">
                                    <ConnectWallet />
                                </div>
                            </div>
                        </>
                        :
                        <>
                            <div className="row align-items-center">
                                <div className="col-lg-12 my-3 my-lg-5">
                                    <div className="glass p-3 p-lg-4">
                                        <h3 className="mb-0 fw-bold">Stack Token</h3>
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="glass p-4 p-lg-5">
                                        <div className="input-mdai">
                                            <input type="text" className="form-control glass p-3" placeholder="Enter Value here" value={amount} onChange={e => setAmount(e.target.value)} />
                                            <img src="./images/mdai.png" alt="mdai" className="img-fluid mdai" />
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <button className="button btn w-100 mt-3 mt-lg-4 fw-bold" onClick={StackToken}>Stack !</button>
                                            </div>
                                            <div className="col-md-6">
                                                <div>
                                                    <button className="button btn w-100 mt-3 mt-lg-4 fw-bold">Un-Stack !</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Details />
                        </>
                }
            </div>
        </section>
    )
}

export default Stacking