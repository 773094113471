import { createSlice } from "@reduxjs/toolkit";

export const contractSlice = createSlice({
    name:"contracts",
    initialState:{
        daiToken:"",
        MSCoin :"",
        TokenFarm:"",
        MaticStake:"",
        account :"",
    },
    reducers:{
        setDaiToken : (state,action) => {
            state.daiToken = action.payload
        },
        setMSCoin : (state,action) => {
            state.MSCoin = action.payload
        },
        setTokenFarm : (state,action) => {
            state.TokenFarm = action.payload
        } ,
        setMaticStake : (state,action) => {
            state.MaticStake = action.payload
        } ,
        
        setAccount : (state,action) => {
            state.account = action.payload
        }
    }
})

export const { setDaiToken, setMSCoin, setTokenFarm,setMaticStake , setAccount } = contractSlice.actions

export default contractSlice.reducer