import React from 'react'

const About = () => {
    return (
        <section className="">
            <div className="container my-3 my-lg-5">
                <div className="row align-items-center">
                    <div className="col-lg-6 my-3">
                        <div className="glass p-5">
                            <img src="./images/mainBanner.png" alt="mainBanner" className="img-fluid py-lg-5 py-3" />
                        </div>
                    </div>
                    <div className="col-lg-6 my-3">
                        <div className="p-3">
                            <h1 className="fw-bold lh-base display-4">
                                About Us
                            </h1>
                            <h4 className="lh-lg my-3 my-lg-4">Many long-term crypto holders look at staking as a way of making their assets work for them by generating rewards, rather than collecting dust in their crypto wallets.
Staking has the added benefit of contributing to the security and efficiency of the blockchain projects you support. By staking some of your funds, you make the blockchain more resistant to attacks and strengthen its ability to process transactions. (Some projects also award “governance tokens” to staking participants, which give holders a say in future changes and upgrades to that protocol.)</h4>
                            <button className="btn button px-3 px-lg-5 fw-bold">Let's Stack</button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default About